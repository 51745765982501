import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { formatISO } from "date-fns";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { eventHealthandColor, eventHealthandIcon } from "../../constants/commonConstants";
import { useSelectedTenant } from "../../hooks/useSelectedTenant";
import { typography } from "../../shared/theme-constants";

const ReportsList = ({ providersData = [] }) => {
  const reportsFeedFilter = useSelector((state) => state.reportsFeedFilters);
  const selectedTenant = useSelectedTenant();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [expandedPanels, setExpandedPanels] = useState([]); // Track expanded accordions

  const handleRedirectToEvents = (provider_id, provider_check = null) => {
    // Create a URLSearchParams object
    const params = new URLSearchParams();

    // Add query parameters by encoding them.
    params.set("provider_uuid", encodeURIComponent(provider_id));
    params.set("provider_type", encodeURIComponent(reportsFeedFilter?.type));
    params.set("tenant_id", selectedTenant);
    params.set(
      "startDate",
      formatISO(reportsFeedFilter?.timestamps?.startDate, { representation: "date" })
    );
    params.set(
      "endDate",
      formatISO(reportsFeedFilter?.timestamps?.endDate, { representation: "date" })
    );

    // Conditionally add provider_check if it's not null
    if (provider_check) {
      params.set("provider_check", encodeURIComponent(provider_check));
    }
    // Dispatch to update events filters in Redux
    const filterData = {
      provider_uuid: provider_id,
      provider_type: reportsFeedFilter?.type,
      provider_check: provider_check || null,
    };
    // Construct the full URL
    const url = `/events?${params.toString()}`;
    // Open the URL in a new tab
    window?.location?.replace(url);
  };

  const renderExpandButton = (item, hideBox = false) => {
    return (
      <Box
        sx={{
          textAlign: "center",
          padding: "10px 0",
          backgroundColor: "transparent",
          borderTop: "0.5px solid #EBEBEB",
          cursor: "pointer",
          display: hideBox && "none",
        }}
        key={item.provider_uuid}
        onClick={() => {
          setExpandedPanels((prevState) =>
            prevState.includes(item.provider_uuid)
              ? prevState.filter((panel) => panel !== item.provider_uuid)
              : [...prevState, item.provider_uuid]
          );
        }}
      >
        <Stack direction="row" gap={2} justifyContent="center" alignItems="center">
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(12),
              fontWeight: typography.font_weight.regular,
              color: "var(--grey-700)",
            })}
          >
            {expandedPanels.includes(item.provider_uuid) ? "Show Less" : "Show More"}
          </Typography>
          <ExpandMoreIcon
            sx={{
              color: "var(--dimmed-black)",
              width: "20px",
              height: "20px",
              ...(expandedPanels.includes(item.provider_uuid)
                ? { transform: "rotate(180deg)" }
                : {}),
            }}
          />
        </Stack>
      </Box>
    );
  };

  return (
    <Box
      sx={(theme) => ({
        padding: "48px 150px",
        paddingTop: "0px",
        marginTop: "250px",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        rowGap: "18px",
        columnGap: "48px",
        [theme.breakpoints.down("tm")]: {
          padding: "48px 50px",
          paddingTop: "0px",
          marginTop: "230px",
        },
        [theme.breakpoints.down("lt")]: {
          marginTop: "330px",
        },
      })}
    >
      {providersData.map((item, index) => (
        <Accordion
          sx={(theme) => ({
            width: 372,
            border: "0.5px solid #EBEBEB",
            boxShadow: "unset",
            borderRadius: "6px",
            height: "fit-content",
            [theme.breakpoints.down("sl")]: {
              width: "100%",
            },
            "&::before": {
              content: "none", // Removes the pseudo-element
            },
            "&.Mui-expanded": {
              margin: 0, // Set margin to 0 when Accordion is expanded
            },
          })}
          key={item.provider_uuid}
          expanded={expandedPanels.includes(item.provider_uuid)} // Check if this panel is expanded
        >
          <Box>
            <AccordionSummary
              expandIcon={null}
              sx={{
                borderBottom: "0.5px solid #EBEBEB",
                padding: "16px",
                "& .MuiAccordionSummary-content": {
                  margin: 0,
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  margin: 0, // Set margin to 0 for the expanded AccordionSummary content
                },
              }}
              onClick={() => handleRedirectToEvents(item.provider_uuid)}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Stack flexDirection="column" gap={3} alignItems="center">
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    gap={2.5}
                    sx={{ alignSelf: "flex-start" }}
                  >
                    <img
                      style={{ width: "20px", height: "20px", objectFit: "contain" }}
                      src={item.provider_logo_url}
                    />
                    <Typography
                      sx={(theme) => ({
                        fontSize: theme.typography.pxToRem(16),
                        fontWeight: typography.font_weight.mediumx,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "1",
                        WebkitBoxOrient: "vertical",
                        color: "var(--black)",
                        opacity: 0.87,
                      })}
                    >
                      {item.provider_display_name}
                    </Typography>
                    <Tooltip
                      title={`List of checks that are being done for ${item.provider_display_name}`}
                      placement="bottom"
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            color: "var(--black)",
                            backgroundColor: "#E6E6E6",
                            fontSize: "0.7em",
                          },
                        },
                      }}
                    >
                      <InfoOutlinedIcon
                        sx={{ width: "14px", height: "14px" }}
                        fontSize="small"
                        color="#00000099"
                      />
                    </Tooltip>
                  </Stack>
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(14),
                      fontWeight: typography.font_weight.regular,
                      color: "var(--grey-700)",
                    })}
                  >
                    {`${item.checks?.completed} of ${item.checks?.total} checks done`}
                  </Typography>
                </Stack>
                <Stack flexDirection="row" alignItems="center" gap={2}>
                  <Box
                    sx={(theme) => ({
                      borderRadius: "100px",
                      backgroundColor: eventHealthandColor[item.health],
                      padding: "5px 10px",
                      width: "87px",
                      height: "28px",
                    })}
                  >
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="center"
                      gap={1.5}
                    >
                      <img
                        src={`${process.env.REACT_APP_CDN_BASE_URL}/images/${
                          eventHealthandIcon[item.health]
                        }`}
                        style={{ width: "14px", height: "14px", objectFit: "contain" }}
                      />
                      <Typography
                        sx={(theme) => ({
                          fontSize: theme.typography.pxToRem(12),
                          fontWeight: typography.font_weight.regular,
                          color: "var(--grey-800)",
                        })}
                      >
                        {item.health_display_name}
                      </Typography>
                    </Stack>
                  </Box>
                  <ExpandMoreIcon
                    sx={{
                      transform: "rotate(270deg)",
                      color: "var(--dimmed-black)",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </Stack>
              </Box>
            </AccordionSummary>
            {renderExpandButton(item, expandedPanels.includes(item.provider_uuid))}
          </Box>
          <AccordionDetails
            sx={{
              padding: 0,
              "&:last-child": { paddingBottom: 0 },
              maxHeight: "273px",
              overflow: "scroll",
            }}
          >
            {item.checks?.statuses.map((check, index) => (
              <Stack
                key={check.provider_check_uuid}
                flexDirection="row"
                alignItems="center"
                sx={{ width: "100%", borderBottom: "0.5px solid #EBEBEB" }}
              >
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  gap={1}
                  sx={{ width: "70%", padding: "12px 20px", borderRight: "0.5px solid #EBEBEB" }}
                >
                  <img
                    src={`${process.env.REACT_APP_CDN_BASE_URL}/images/text_check.png`}
                    style={{ width: "18px", height: "18px", objectFit: "contain" }}
                  />
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(14),
                      fontWeight: typography.font_weight.regular,
                      color: "var(--black)",
                      opacity: 0.87,
                    })}
                  >
                    {check.provider_check_display_name}
                  </Typography>
                </Stack>
                <Stack
                  flexDirection="row"
                  gap={1.5}
                  alignItems="center"
                  justifyContent="center"
                  sx={{ width: "30%", padding: "12px 20px", cursor: "pointer" }}
                  onClick={() =>
                    handleRedirectToEvents(item.provider_uuid, check.provider_check_name)
                  }
                >
                  <img
                    src={`${process.env.REACT_APP_CDN_BASE_URL}/images/${
                      eventHealthandIcon[check.health]
                    }`}
                    style={{ width: "14px", height: "14px", objectFit: "contain" }}
                  />
                  <Typography
                    sx={(theme) => ({
                      fontSize: theme.typography.pxToRem(12),
                      fontWeight: typography.font_weight.regular,
                      color: "var(--grey-800)",
                    })}
                  >
                    {check.health_display_name}
                  </Typography>
                  <ExpandMoreIcon
                    sx={{
                      transform: "rotate(270deg)",
                      color: "var(--dimmed-black)",
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                    }}
                  />
                </Stack>
              </Stack>
            ))}
          </AccordionDetails>
          {renderExpandButton(item)}
        </Accordion>
      ))}
    </Box>
  );
};

export default ReportsList;
