export const SEVERITY = "Severity";
export const METRICS = "Metrics";
export const PATTERN = "Pattern";
export const DIMENSIONS_NAMES = "DIMENSION/NAMES";
export const DIMENSION_VALUES = "DIMENSION/VALUES";
export const KPIS = "kpis";
export const PATTERN_TYPE = "pattern_type";
export const RESET_COLLABORATE_FILTERS = "RESET_COLLABORATE_FILTERS";
export const IS_FETCHED = "IS_FETCHED";
export const PIPELINE_SCHEDULE = "PIPELINE_SCHEDULE";
export const CHART_TYPE = "CHART_TYPE";
export const METRIC_COMPARE = "metric_compare";
export const STACKED_AREA = "stacked_area";
export const HUNDRED_PERCENT_STACKED_AREA = "hundred_precent_stacked_area";
export const BAR_CHART = "bar_chart";
export const TABLE_DATA = "table_data";

export const IS_FORECAST_ENABLED = "IS_FORECAST_ENABLED";
export const IS_LOG_SCALE_ENABLED = "IS_LOG_SCALE_ENABLED";

export const DROPDOWN_TYPE = {
  SEVERITY: [
    { severity_type: "high", severity_value: "High" },
    { severity_type: "medium", severity_value: "Medium" },
    { severity_type: "low", severity_value: "Low" },
  ],
  PATTERN: [
    { pattern_type: "spike_up", pattern_value: "Spike Up" },
    { pattern_type: "spike_down", pattern_value: "Spike Down" },
    // { pattern_type: "up_trend", pattern_value: "Up Trend" }, // Commented as part of DEV-4582
    // { pattern_type: "down_trend", pattern_value: "Down Trend" }, // Commented as part of DEV-4582
    // { pattern_type: "nnign", pattern_value: "No News is Good News" }, // Commented as part of DEV-4582
  ],
};
