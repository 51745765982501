import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { colors } from "../../../shared/theme-constants";
import { formatDatePerFrequency } from "../../../utils/dateUtils";
import "./DimensionsBreakupChart.css";
import { nFormatter } from "../../../utils/stringUtils";
import moment from "moment-timezone";
import { DEFAULT_TIMEZONE } from "../../../constants/commonConstants";

const DimensionsBreakupChart = ({ metric, data, chartType, pipelineSchedule, isTimeRequired = false, timezone= null }) => {
  const [dimensionsChartData, setDimensionsChartData] = useState([]);
  const [xAxisCategories, setXAxisCategories] = useState([]);

  // Function to add the The values at same indexes in Others array
  function addIndexes(arrays) {
    const length = arrays[0].length;
    let result = new Array(length).fill({x : 0, y:0});

    const xValueMap = new Map();

    arrays.forEach((array) => {
      array.forEach((point) => {
        const { x, y } = point;
        if (!xValueMap.has(x)) {
          xValueMap.set(x, 0);
        }
        if (y !== null && y !== undefined) {
          xValueMap.set(x, xValueMap.get(x) + y);
        }
      });
    });

     result = Array.from(xValueMap.entries()).map(([x, y]) => ({ x, y }));

    return result;
  }

  // This will decide how many ticks are present on the x axis and what is the second wise gap between the ticks
  const generateTimeSeriesChartTickInterval = (frequency) => {
    switch (frequency) {
      case "h":
        if (data?.length) {
        // The below code is to limit the tick intervals on the basis of total number of days in hourly pipeline.
        // we take the first and last timestamp of the data and and calculate the number of days and total hours ex: 7 days then total hours 7 * 24.
        // then we divide total hours with max ticks that we want on our chart which is 16 we are taking right now.
        // the number we get is then determines how much time interval will be there between each tick for hourly pipeline
        const firstTimestampDate = moment.tz(data[0].timestamp, timezone === null ? DEFAULT_TIMEZONE : timezone);
        const lastTimestampDate = moment.tz(data[data.length - 1].timestamp, timezone === null ? DEFAULT_TIMEZONE : timezone);
        const totalHours = lastTimestampDate.diff(firstTimestampDate, 'hours');
        // Step 3: Determine tick interval
        const maxTicks = 12;
        let tickInterval = Math.ceil(totalHours / maxTicks); // Tick interval in hours
        if (tickInterval < 1) {
          tickInterval = 1;
        }
        return tickInterval * 3600 * 1000;
      }
      return 3600 * 1000;
      case "d":
        return 24 * 3600 * 1000;
      case "w":
        return 14 * 24 * 3600 * 1000;
      case "m":
        return 30 * 24 * 3600 * 1000;
      case "q":
        return 90 * 24 * 3600 * 1000;
      default:
        return 3600 * 1000;
    }
  };

  useEffect(() => {
    if (data?.length) {
      let dimensionBreakupdata = [...data];
      let uniqueDimensions = [];
      setXAxisCategories(dimensionBreakupdata.map((data) => data.timestamp));

      // unique dimensions
      dimensionBreakupdata.forEach((data) => {
        if (data.results.length > 0) {
          data.results.forEach((result) => {
            if (!uniqueDimensions.includes(result.dim_val)) {
              uniqueDimensions.push(result.dim_val);
            }
          });
        }
      });

      // iterate on result set to find if that dim is present on that timestamp or not. if not populate null for that dim for that timestamp
      let dimensionSeriesArray = [];
      uniqueDimensions.forEach((dimension) => {
        let dataPoints = [];
        dimensionBreakupdata.forEach((data) => {
          let matchedEntry = data.results.find((item) => item.dim_val === dimension);
          matchedEntry ? dataPoints.push({x : moment(data?.timestamp)?.valueOf(), y: +matchedEntry.y  }) : dataPoints.push({x: moment(data?.timestamp)?.valueOf(), y : null});
        });
        dimensionSeriesArray.push({
          name: dimension,
          data: dataPoints,
        });
      });

      // sort the array based on additon of the values
      dimensionSeriesArray.sort((a, b) => {
        let summationA = a?.data.reduce((acc, curr) => acc + curr?.y, 0);
        let summationB = b?.data.reduce((acc, curr) => acc + curr?.y, 0);
        return summationB - summationA;
      });

      let resultArray = [];
      // check if Length is greater than 6
      if (dimensionSeriesArray.length > 6) {
        let slicedArray = dimensionSeriesArray.slice(5);
        let mappedArray = slicedArray.map((element) => element.data);
        let OthersArray = addIndexes(mappedArray);
        // First  6 Elements in start array
        let StartArray = dimensionSeriesArray.slice(0, 5);

        // Spread the first 5 elements in the result set
        resultArray = [...StartArray];
        // Push the Others array

        resultArray.push({
          name: "Others",
          data: OthersArray,
        });
      } else {
        resultArray = [...dimensionSeriesArray];
      }
      // Add Colours to each of them
      resultArray.forEach((series, index) => (series.color = colors.dimensions_breakup[index]));

      setDimensionsChartData(resultArray);
    }
  }, []);

  const generateChartOptions = () => {
    let options = {
      chart: {
        type: "area",
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      accessibility: {
        enabled: false,
      },
      xAxis: {
        categories: xAxisCategories?.map(element => moment(element).valueOf()),
        tickInterval: generateTimeSeriesChartTickInterval(pipelineSchedule),
        tickColor: colors.gray[350],
        tickLength: 14,
        tickWidth: 0.5,
        labels: {
          style: {
            color: colors.gray[575],
            fontSize: "0.9em",
          },
          formatter: function () {
            return formatDatePerFrequency(
              this.value,
              pipelineSchedule,
              timezone === null ? DEFAULT_TIMEZONE : timezone
            );
          },
        },
      },
      yAxis: {
        labels: {
          style: {
            color: colors.gray[575],
            tickColor: colors.gray[350],
            fontSize: "0.9em",
          },
          formatter: function () {
            if (chartType === "stacked_area") {
              if (metric.kpi_format === "percentage") {
                return `${(this.value * 100).toFixed(1)}%`;
              } else {
                return nFormatter(this.value, 1);
              }
            } else {
              return `${this.value}%`;
            }
          },
        },
        title: {
          enabled: false,
        },
      },
      tooltip: {
        crosshairs: true,
        shared: true,
        formatter: function () {
          const points = this.points;
          let tooltip = `<b>${moment(this.x)
            .tz(timezone === null ? DEFAULT_TIMEZONE : timezone)
            .format(!isTimeRequired ? "DD MMM, YYYY" : "DD MMM, YYYY, h A")}</b><br/>`; // Format date
          points.forEach((point) => {
            tooltip += `<span style="color:${point.color}">\u25CF</span> 
            ${point.series.name}: <b>${
              chartType === "stacked_area"
                ? metric.kpi_format === "percentage"
                  ? `${(point.y * 100).toFixed(1)}%`
                  : nFormatter(point.y, 1)
                : nFormatter(point.percentage, 1) + "%"
            }</b><br/>`;
          });
          return tooltip;
        },
      },
      plotOptions: {
        area: {
          fillOpacity: 1,
          stacking: chartType === "stacked_area" ? "normal" : "percent",
          marker: {
            enabled: false,
            symbol: "circle",
          },
        },
      },
      series: dimensionsChartData,
    };
    return options;
  };

  return <HighchartsReact highcharts={Highcharts} options={generateChartOptions()} />;
};

export default DimensionsBreakupChart;
