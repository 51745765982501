import React from "react";

import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { REPORTS_FEED_UPDATE_TYPE } from "../../constants/reportsConstants";
import { colors, typography } from "../../shared/theme-constants";
import NavigationBar from "../common/NavigationBar";
import TimeFilter from "./components/TimeFilter";
import { useSearchParams } from "react-router-dom";

const StyledCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 37.5),
  borderRadius: 12,
  backgroundColor: colors.white,
  position: "relative",
  width: "100%",
  position: "fixed",
  zIndex: 1000,
  [theme.breakpoints.down("tm")]: {
    padding: theme.spacing(8, 5),
  },
}));

const EventSummaryCard = ({ providerTypes, handleProviderType, sx, ...restProps }) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const reportsFeedFilter = useSelector((state) => state.reportsFeedFilters);
  const description = providerTypes?.length ? providerTypes.find(item => item.value === reportsFeedFilter?.type)?.description : "yeh daalo";
  const updateProviderType = (event, updatedType) => {
    // Check if the type value is different from the current value
    if (updatedType !== reportsFeedFilter?.type) {
      const updatedParams = new URLSearchParams(searchParams);
      updatedParams.set("type", updatedType);
      dispatch({
        type: REPORTS_FEED_UPDATE_TYPE,
        payload: updatedType,
      });
      handleProviderType(updatedType);
      setSearchParams(updatedParams);
    }
  };

  return (
    <StyledCard sx={sx} {...restProps}>
      <Stack flexDirection="column" gap={4}>
        <Typography
          sx={(theme) => ({
            fontSize: theme.typography.pxToRem(30),
            color: "var(--charcoal-grey)",
            fontWeight: typography.font_weight.semiBold,
            [theme.breakpoints.down("tm")]: {
              fontSize: theme.typography.pxToRem(24),
            },
          })}
        >
          Weekly Report
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={(theme) => ({
            [theme.breakpoints.down("lt")]: {
              flexDirection: "column",
              gap: "12px",
            },
          })}
        >
          <Stack
            direction="row"
            gap={3}
            alignItems="center"
            sx={(theme) => ({
              [theme.breakpoints.down("lt")]: {
                flexDirection: "column",
              },
            })}
          >
            <Typography
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(16),
                color: "var(--charcoal-grey)",
                fontWeight: typography.font_weight.semiBold,
              })}
            >
              Events Type:
            </Typography>
            <NavigationBar
              onValueChange={updateProviderType}
              value={reportsFeedFilter ? reportsFeedFilter.type : null}
              options={providerTypes}
            />
          </Stack>
          <TimeFilter />
        </Stack>
        <Typography
          sx={(theme) => ({
            fontSize: theme.typography.pxToRem(16),
            color: "var(--grey-700)",
            fontWeight: typography.font_weight.regular,
            [theme.breakpoints.down("tm")]: {
              fontSize: theme.typography.pxToRem(12),
            },
          })}
        >
          {description}
        </Typography>
      </Stack>
    </StyledCard>
  );
};

export default EventSummaryCard;
