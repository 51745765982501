import styled from "@emotion/styled";
import { Card, CardContent, CardHeader, CardMedia, Stack } from "@mui/material";
import React from "react";
import { colors, typography } from "../../../shared/theme-constants";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4, 8),
  [theme.breakpoints.down("lt")]: { padding: `${theme.spacing(4)}` },
  borderRadius: 12,
  overflow: "unset",
  backgroundColor: colors.white,
  position: "relative",
}));
const EventDetailCards = ({ title, image, children, sx, cardContentStyles, style, cardHeaderStyles={}, ...restProps }) => {
  return (
    <StyledCard sx={sx} style={style}>
      <Stack direction="row" sx={{ alignItems: "center", ...(!title ? { display: "none" } : {}) }}>
        <CardMedia
          component="img"
          sx={{ width: 40, height: 40 }}
          image={image ? `${process.env.REACT_APP_CDN_BASE_URL}/images/${image}.png` : null}
          alt="Card image"
        />
        <CardHeader
          title={title}
          sx={{
            padding: "16px 6px",
            ...cardHeaderStyles,
          }}
          titleTypographyProps={{
            sx: {
              fontSize: 18,
              fontWeight: typography.font_weight.semiBold,
              color: "var(--charcoal-grey)",
            },
          }}
        />
      </Stack>
      <CardContent sx={{...cardContentStyles}}>{children}</CardContent>
    </StyledCard>
  );
};

export default EventDetailCards;
