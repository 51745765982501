
export const compareMetricHighChartLayout = {
  credits: {
    enabled: false // Disable Highcharts credit
  },
  accessibility: {
    enabled: false,
  },
  title: {
    text: "Timeline View",
    align: "left",
    x: -5,
    style: {
      color: "var(--purple-fog)",
      fontSize: "1.5em",
    },
  },
  legend: {
    enabled: true,
    layout: 'horizontal', // Align legend items horizontally
    align: 'center', // Align legend center
    verticalAlign: 'bottom', // Align legend to bottom
    itemStyle: {
      color: '#71717a',
      fontSize: '14px'
    },
    reversed: false // Reverse legend order
  },
  xAxis: {
    type: 'datetime',
    lineWidth: 0,
    showLine: false,
    gridLineWidth: 1,
    title: {
      text: '',
      style: {
        color: '#d4d4d8'
      }
    },
  },
  yAxis: {
    title: {
      text: null
    },
  },
  yAxis2: {
    type: 'linear', // Use linear scale for percentage y-axis
    tickFormat: 'p', // Display ticks as percentages
    opposite: true, // Position the axis on the right side
  },
  plotOptions: {
    series: {

      events: {
        legendItemClick: function (event) {
          const series = this.chart.series;
          let enabledCount = 0;
          series.forEach(serie => {
            if (serie.visible) {
              enabledCount++;
            }
          });
          if (enabledCount === 1 && this.visible) {
            // Prevent disabling the last enabled legend
            event.preventDefault();
          }
        }
      },

      marker: {
        lineColor: 'auto', // Match the line color with the plot lines
      },
    },
    column: {
      pointWidth: 15, // Set fixed width for columns
      pointPadding: 1, // Space between columns
      groupPadding: 1, // Space between groups
  },
  },
};