import { Box, Stack, Typography, Divider } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import axios from "axios";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import { useDispatch, useSelector } from "react-redux";
import {
  getStorePreferenceDetails,
  updateStorePreferenceDetails,
} from "../../actions/preferenceActions";
import { typography } from "../../shared/theme-constants";
import StyledTabs from "../common/mui-wrapper-components/StyledTabs";
import { StyledButton } from "../common/mui-wrapper-components/Button";
import { Loading } from "../common/styled-components";
import { HTTP_STATUS } from "../../shared/enums/http-status";
import { useNavigate } from "react-router-dom";
import { useSelectedTenant } from "../../hooks/useSelectedTenant";
import MultiSelect from "../common/mui-wrapper-components/Select/MultiSelect";
import StoreProviderDrawer from "./StoreProviderDrawer";
import { currencyMap } from "../../constants/currencyMapConstants";
import { timezoneMap } from "../../constants/timezoneMapConstants";

const MY_PROFILE_TABS = [{ value: "stores", label: "Stores" }];

const StorePreference = () => {
  let timezones = timezoneMap;
  let currencies = currencyMap.map((currency) => {
    const obj = {
      label: `${currency.code} - ${currency.name} (${currency.symbol})`,
      value: currency.code,
    };
    return obj;
  });
  const [storeOptions, setStoreOptions] = useState([]);
  const [selectedStoreDetails, setSelectedStoreDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [openStorePreferences, setOpenStorePreferences] = useState(false);
  const [openProviderDrawer, setOpenProviderDrawer] = useState(false);
  const [storePreferenceData, setStorePreferenceData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedTenant = useSelectedTenant();
  const tenantGlobalFilter = useSelector((state) => state.tenantGlobalFilter?.appliedFilter);

  // This useEffect fetches all the stores available for customers.
  useEffect(() => {
    let isMounted = true;
    // Below function, On click of edit button infront of store name it fetch the respective store's details to show store's details.
    const fetchStoreDetails = (store) => {
      setLoading(true);
      setSelectedStoreDetails(store);
      dispatch(getStorePreferenceDetails(store?.org_uuid)).then((response) => {
        setStorePreferenceData(response.data);
        setSelectedCurrency({
          label: `${response.data?.preferences?.currency} - ${currencyMap.find((currency) => currency.code === response.data?.preferences?.currency)?.name} (${currencyMap.find((currency) => currency.code === response.data?.preferences?.currency)?.symbol})`,
          value: response.data?.preferences?.currency
        });
        setSelectedTimezone(timezoneMap.find((timezone) => timezone.value === response.data?.preferences?.timezone));
        // To open or close the specific store's preferences we use below state.
        setOpenStorePreferences(true);
        setLoading(false);
      });
    };
    fetchStoreDetails(tenantGlobalFilter);
    return () => {
      isMounted = false;
    };
  }, [dispatch, tenantGlobalFilter]);

  // On update of either currency or timezone, we need to make an update call so we are using a common function which gets updated value and the field which is updated.
  // and then we send the updated data to backend and it update state based on the updated field.
  const updateStoreDetails = (updatedVal, updatedField) => {
    const payload = { ...storePreferenceData };
    if (updatedField === "currency") {
      payload.preferences.currency = updatedVal.value;
    } else if (updatedField === "timezone") {
      payload.preferences.timezone = updatedVal.value;
    }
    dispatch(updateStorePreferenceDetails(storePreferenceData.org_uuid, payload, selectedStoreDetails.display_name)).then(
      (response) => {
        if (updatedField === "currency") {
          setSelectedCurrency(updatedVal);
        } else if (updatedField === "timezone") {
          setSelectedTimezone(updatedVal);
        }
      }
    );
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <Stack
        direction="column"
        gap={10}
        sx={{
          height: "85vh",
          alignItems: "flex-start",
          pl: 8,
          pr: 8,
          overflowY: "scroll",
        }}
      >
        <Box>
          <StyledTabs
            sx={{ padding: "20px 0" }}
            value={MY_PROFILE_TABS[0].value}
            options={[{...MY_PROFILE_TABS[0], label: openStorePreferences ? `${selectedStoreDetails?.display_name || ''}` : MY_PROFILE_TABS[0].label }]}
          />
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(13),
              fontWeight: typography.font_weight.regular,
            })}
          >
            To configure settings for a different store, select the store from the top right dropdown on the page.
          </Typography>
        </Box>
        <Box
          sx={(theme) => ({
            width: "100%",
          })}
        >
          <Stack direction="column" gap={5} sx={{ width: "100%" }}>
            <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center" }}>
              <Stack direction="column" gap={1} sx={{ alignItems: "flex-start" }}>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(16),
                    fontWeight: typography.font_weight.semiBold,
                  })}
                >
                  Currency
                </Typography>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(13),
                    fontWeight: typography.font_weight.light,
                  })}
                >
                  Select the primary currency for all transactions and financial displays in your store.
                </Typography>
              </Stack>
              <MultiSelect
                multiple={false}
                disableClearable
                placeholder="Select currency"
                onChange={(event, newVal) => updateStoreDetails(newVal, "currency")}
                value={selectedCurrency}
                options={currencies}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                sx={{ flexGrow: 1, minWidth: "250px" }}
                optionsCheckbox={false}
                enableEllipsis
              />
            </Stack>
            <Divider orientation="horizontal" variant="middle" flexItem />
            <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center" }}>
              <Stack direction="column" gap={1} sx={{ alignItems: "flex-start" }}>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(16),
                    fontWeight: typography.font_weight.semiBold,
                  })}
                >
                  Time zone
                </Typography>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(13),
                    fontWeight: typography.font_weight.light,
                  })}
                >
                  Select the primary timezone for your store operations.
                </Typography>
              </Stack>
              <MultiSelect
                multiple={false}
                disableClearable
                placeholder="Select timezone"
                onChange={(event, newVal) => updateStoreDetails(newVal, "timezone")}
                value={selectedTimezone}
                options={timezones}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                sx={{ flexGrow: 1, minWidth: "350px" }}
                optionsCheckbox={false}
                enableEllipsis
              />
            </Stack>
            <Divider orientation="horizontal" variant="middle" flexItem />
            <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center" }}>
              <Stack direction="column" gap={1} sx={{ alignItems: "flex-start" }}>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(16),
                    fontWeight: typography.font_weight.semiBold,
                  })}
                >
                  Event Providers
                </Typography>
                <Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(13),
                    fontWeight: typography.font_weight.light,
                  })}
                >
                  Please configure the providers for errors and outages events.
                </Typography>
              </Stack>
              <SettingsIcon
                sx={{
                  ":hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => {
                  setOpenProviderDrawer(true);
                }}
                fontSize="medium"
                color="var(--resolution-blue)"
              />
            </Stack>
            <Divider orientation="horizontal" variant="middle" flexItem />
            <Box sx={{ width: "20%" }}>
              <StyledButton
                onClick={() => {
                  setSelectedStoreDetails(null);
                  setStorePreferenceData(null);
                  setSelectedCurrency(null);
                  setSelectedTimezone(null);
                  setOpenStorePreferences(false);
                }}
                sx
              >
                Back
              </StyledButton>
            </Box>
          </Stack>
        </Box>
        {openProviderDrawer ? (
          <StoreProviderDrawer
            isStoreProviderDrawerOpen={openProviderDrawer}
            onStoreProviderDrawerClose={() => setOpenProviderDrawer(false)}
            storePreferenceData={storePreferenceData}
            onClose={() => setOpenProviderDrawer(false)}
            selectedStoreDetails={selectedStoreDetails}
          />
        ) : null}
      </Stack>
    </>
  );
};

export default StorePreference;
