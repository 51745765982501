import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography
} from "@mui/material";
import axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SHOW_MODAL, STRIPE_BILLING } from "../../constants/modalConstants";
import { useSelectedTenant } from "../../hooks/useSelectedTenant";
import { SET_SNACKBAR } from "../../reducers/notificationSnackbarReducer";
import { HTTP_STATUS } from "../../shared/enums/http-status";
import { typography } from "../../shared/theme-constants";
import StyledTabs from "../common/mui-wrapper-components/StyledTabs";
import { Loading } from "../common/styled-components";
import { PAYMENT_INITIATED } from "../../constants/commonConstants";

const BILLING_TABS = [{ value: "billing", label: "Billing" }];
const url = process.env.REACT_APP_BASE_URL;

const PlanCard = ({ title, plan }) => {
  const dispatch = useDispatch();
  const globalFilter = useSelector((state)=> state.tenantGlobalFilter?.appliedFilter);
  const userObject = useSelector((state) => state.userLogin);

  if (!plan) return <></>;

  const { name, price, billing_cycle, description, actions, is_top_plan } = plan;

  const handleCancelPlan = async (planType) => {
    // POST request to cancel plan
    try {
      const { data } = await axios.post(`${url}billing/cancel`, {
        tenant_id: globalFilter.tenant_id,
        org_uuid: globalFilter.org_uuid,
        plan: planType
      });
      if (data.success) {
        dispatch({
          type: SET_SNACKBAR,
          snackbarOpen: true,
          snackbarMessage: data.message,
          snackbarType: "success",
        });
      } else {
        dispatch({
          type: SET_SNACKBAR,
          snackbarOpen: true,
          snackbarMessage: data.message,
          snackbarType: "error",
        });
      }
    } catch (error) {
      dispatch({
        type: SET_SNACKBAR,
        snackbarOpen: true,
        snackbarMessage: error.message,
        snackbarType: "error",
      });
    }
  };

  const handleUpgradePlan = (planType) => {
    // Check if planType is Current Plan or Add-On Plan
    if (planType === "Current Plan") {
      // push payment_initiated to customer.io
      window?.analytics?.track(PAYMENT_INITIATED, {
        status: "success",
        type: "upgrade",
        plan: "Current Plan",
        user_uuid: userObject?.user_uuid,
        tenant_id: globalFilter?.tenant_id,
        shop: globalFilter?.shopify_shop_name,
      });

      // Send to Shopify Pricing Page
      window.open(
        `${process.env.REACT_APP_SHOPIFY_ADMIN_BASE_URL}/${globalFilter.shopify_shop_name.split(".")[0]}/charges/${process.env.REACT_APP_SHOPIFY_APP_HANDLE}/pricing_plans`,
        "_self"
      );
    } else {
      // push payment_initiated to customer.io
      window?.analytics?.track(PAYMENT_INITIATED, {
        status: "success",
        type: "upgrade",
        plan: "Add-On Plan",
        user_uuid: userObject?.user_uuid,
        tenant_id: globalFilter?.tenant_id,
        shop: globalFilter?.shopify_shop_name,
      });

      dispatch({
        type: SHOW_MODAL,
        payload: {
          modalType: STRIPE_BILLING,
          modalProps: {},
          showCloseButton: true,
        },
      });
    }
  };

  const handleSubscribePlan = (planType) => {
    // Check if planType is Current Plan or Add-On Plan
    if (planType === "Current Plan") {
      // push payment_initiated to customer.io
      window?.analytics?.track(PAYMENT_INITIATED, {
        status: "success",
        type: "select",
        plan: "Current Plan",
        user_uuid: userObject?.user_uuid,
        tenant_id: globalFilter?.tenant_id,
        shop: globalFilter?.shopify_shop_name,
      });

      // Send to Shopify App Store for subscription
      window.open(
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_SHOPIFY_APP_INSTALL_LINK
          : `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SHOPIFY_APP_INSTALL_LINK}`,
        "_self"
      );
    } else {
      // push payment_initiated to customer.io
      window?.analytics?.track(PAYMENT_INITIATED, {
        status: "success",
        type: "select",
        plan: "Add-On Plan",
        user_uuid: userObject?.user_uuid,
        tenant_id: globalFilter?.tenant_id,
        shop: globalFilter?.shopify_shop_name,
      });

      dispatch({
        type: SHOW_MODAL,
        payload: {
          modalType: STRIPE_BILLING,
          modalProps: {},
          showCloseButton: true,
        },
      });
    }
  };

  return (
    <Stack
      direction="row"
      gap={3}
      sx={{ justifyContent: "space-between", alignItems: "flex-start" }}
    >
      {/* Left Section */}
      <Stack direction="column" gap={1} sx={{ alignItems: "flex-start" }}>
        <Typography
          sx={(theme) => ({
            fontSize: theme.typography.pxToRem(16),
            fontWeight: typography.font_weight.semiBold,
            color: "#404040",
          })}
        >
          {title}
        </Typography>
        <Typography
          sx={(theme) => ({
            fontSize: theme.typography.pxToRem(13),
            fontWeight: typography.font_weight.light,
            color: "#737373",
          })}
        >
          Manage your plan
        </Typography>
      </Stack>

      {/* Right Section */}
      <Card
        variant="outlined"
        sx={{
          width: 560,
          borderRadius: 1.5,
          overflow: "hidden",
          borderColor: "#E5E5E5",
        }}
      >
        {/* Card Header */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#FAFAFA",
            padding: 2,
            borderBottom: "1px solid #E5E5E5",
          }}
        >
          {/* Plan Name & Icon */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Box
              sx={{
                width: 28,
                height: 28,
                backgroundColor: "#1D3EA7",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "4px solid rgba(29, 62, 167, 0.10)",
              }}
            >
              <img style={{ width: 16, height: 16, margin: "0 10px 0 10px" }} src={`${process.env.REACT_APP_CDN_BASE_URL}/icons/layers-three.svg`} />
            </Box>
            <Typography variant="subtitle1" fontWeight="600" color="text.primary">
              {name}
            </Typography>
          </Box>

          {/* Actions */}
          <Box sx={{ display: "flex", gap: 2 }}>
            {actions.cancel_plan && (
              <Button
                variant="outlined"
                size="small"
                sx={{
                  color: "text.primary",
                  backgroundColor: "white",
                  borderColor: "#E5E5E5",
                  borderRadius: 1,
                }}
                onClick={() => handleCancelPlan(title)}
              >
                Cancel Plan
              </Button>
            )}
            {actions.upgrade_plan && (
              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: "#1D3EA7",
                  color: "white",
                  borderRadius: 1,
                }}
                onClick={!actions.cancel_plan ? () => handleSubscribePlan(title) : () => handleUpgradePlan(title)}
                disabled={is_top_plan}
              >
                {!actions.cancel_plan ? "Subscribe" : "Upgrade Plan"}
              </Button>
            )}
          </Box>
        </Box>

        {/* Card Content */}
        <CardContent
          sx={(theme) => ({
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 4,
            "&:last-child": {
              paddingBottom: "16px",
            },
          })}
        >
          {/* Price & Description */}
          <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "flex-start", width: "100%" }}>
            <Stack direction="column" gap={1} sx={{ alignItems: "flex-start" }}>
              <Stack direction="row" gap={1} alignItems="center">
                {price !== 0 && (<Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(24),
                    fontWeight: typography.font_weight.semiBold,
                    color: "#171717"
                  })}
                >
                  ${price}
                </Typography>)}
                {price !== 0 && (<Typography
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(14),
                    fontWeight: typography.font_weight.regular,
                    color: "#737373"
                  })}
                >
                  {billing_cycle}
                </Typography>)}
              </Stack>
              <Typography
                sx={(theme) => ({
                  fontSize: theme.typography.pxToRem(14),
                  fontWeight: typography.font_weight.regular,
                  color: "#737373"
                })}
              >
                {description}
              </Typography>
            </Stack>

            {/* Validity (if applicable) */}
            {/* {title === "Current Plan" && (
              <Chip
                label="Valid till 02 March, 2025"
                size="small"
                sx={{
                  backgroundColor: "#ECFDF3",
                  color: "#027A48",
                  fontWeight: "500",
                  borderRadius: 2,
                  fontSize: "12px"
                }}
              />
            )} */}
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};

const BillingPreferences = () => {
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState(null);
  const [billingPreferences, setBillingPreferences] = useState({});
  const [openBillingPreferences, setOpenBillingPreferences] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedTenant = useSelectedTenant();
  const userObject = useSelector((state) => state.userLogin);
  const tenantGlobalFilter = useSelector((state) => state.tenantGlobalFilter?.appliedFilter);

  useEffect(() => {
    const fetchBillingPreferences = async () => {
      try {
        const response = await axios.get(`${url}billing/details`, {
          params: {
            tenant_id: selectedTenant,
            org_uuid: tenantGlobalFilter?.org_uuid,
          },
        });
        if (response.status === HTTP_STATUS.OK && response.data.success) {
          setPlans(response.data.data);
          setLoading(false);
        }
      } catch (error) {
        setIsError(true);
        setErrorMessage(error.message);
        setLoading(false);
      }
    };

    fetchBillingPreferences();
    setLoading(false);
  }, [selectedTenant, tenantGlobalFilter?.org_uuid]);

  const handleBillingPreferenceChange = (event) => {
    const updatedBillingPreferences = _.cloneDeep(billingPreferences);
    updatedBillingPreferences[event.target.name] = event.target.value;
    setBillingPreferences(updatedBillingPreferences);
  };

  if (loading) {
    return <Loading />;
  }

  if (isError) {
    return (
      <Box>
        <Typography variant={typography.body1} color="error">
          {errorMessage}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Stack
        direction="column"
        gap={10}
        sx={{
          height: "85vh",
          alignItems: "flex-start",
          pl: 8,
          pr: 8,
          overflowY: "scroll",
        }}
      >
        <Box>
          <StyledTabs sx={{ padding: "20px 0" }} value={"billing"} options={BILLING_TABS} />
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(13),
              fontWeight: typography.font_weight.regular,
            })}
          >
            To manage billing for a different store, select the store from the top right dropdown on the page.
          </Typography>
        </Box>
        <Box
          sx={(theme) => ({
            width: "100%",
          })}
        >
          <Stack direction="column" gap={6} sx={{ width: "100%" }}>
            {plans?.current_plan && <PlanCard title={`Current Plan`} plan={plans.current_plan} />}
            {plans?.add_on_plan && <PlanCard title={`Add-On Plan`} plan={plans.add_on_plan} />}
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default BillingPreferences;
