import { Box, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser";
import { typography } from "../../shared/theme-constants";

export const StripeBillingScreen = () => {
  const user = useAuthenticatedUser();
  const userObject = useSelector((state) => state.userLogin);
  const navigate = useNavigate();

  const handleBackToShopify = () => {
    navigate("/onboard/app_installation");
  };

  return (
    <Box
      elevation={3}
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "auto",
        width: "100vw",
        minWidth: theme.typography.pxToRem(700),
        maxWidth: theme.typography.pxToRem(700),
        boxShadow: "none",
        backgroundColor: "white",
        [theme.breakpoints.up("tm")]: {
          width: `${theme.typography.pxToRem(460)} !important`, // Increase height on smaller screens
        },
      })}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20px",
          marginBottom: "20px",
          width: "100%",
          [theme.breakpoints.down("lt")]: { marginTop: "70px" },
        })}
        gap={6}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
            width: "100%",
          }}
          gap={6}
        >
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(22),
              fontWeight: typography.font_weight.mediumx,
              color: "var(--grey-900)",
              textAlign: "center",
            })}
          >
            Boost your digital efficiency with
            <br />
            Out Of The Blue Add-ons
          </Typography>
          <Typography
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(12),
              fontWeight: typography.font_weight.mediumx,
              color: "var(--grey-700)",
              textAlign: "center",
            })}
          >
            Stay ahead of platform errors and secure expert support with
            <br />
            our tailored solutions, exclusive to Blotout customers.
          </Typography>
        </Box>
        <Paper
          elevation={3}
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "auto",
            width: "auto",
            // maxWidth: theme.typography.pxToRem(460),
            boxShadow: "0 5px 20px 0 rgba(0, 0, 0, 0.15)",
            backgroundColor: "var(--white)",
            padding: "24px",
            borderRadius: "8px",
            gap: "24px",
          })}
        >
          <Stack
            direction="row"
            gap={4}
            sx={(theme) => ({
              alignItems: "center",
              justifyContent: "space-between",
              [theme.breakpoints.down("lt")]: { flexDirection: "column" },
            })}
          >
            <Box
              sx={{
                border: "1px solid #2C50FF33",
                borderRadius: "8px",
                padding: "8px",
                backgroundColor: "#225CEB05",
              }}
            >
              <stripe-buy-button
                buy-button-id={
                  process.env.REACT_APP_STRIPE_PULSE_PIXEL_INSURANCE_MONTHLY_PAYMENT_LINK
                }
                publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
                client-reference-id={user?.tenant_id}
                customer-email={user?.email}
              ></stripe-buy-button>
            </Box>
            <Box
              sx={{
                border: "1px solid #2C50FF33",
                borderRadius: "8px",
                padding: "8px",
                backgroundColor: "#225CEB05",
              }}
            >
              <stripe-buy-button
                buy-button-id={process.env.REACT_APP_STRIPE_PULSE_DONE_FOR_YOU_MONTHLY_PAYMENT_LINK}
                publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
                client-reference-id={user?.tenant_id}
                customer-email={user?.email}
              ></stripe-buy-button>
            </Box>
          </Stack>
        </Paper>
      </Box>
    </Box>
  );
};
