import { Box, Chip, Stack, ToggleButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getDimensionValues,
  getRelatedMetrics,
} from "../../../actions/collaborateFilterActions";
import {
  CHART_TYPE,
  DROPDOWN_TYPE,
  HUNDRED_PERCENT_STACKED_AREA,
  IS_FETCHED,
  KPIS,
  METRIC_COMPARE,
  PATTERN,
  PATTERN_TYPE,
  PIPELINE_SCHEDULE,
  SEVERITY,
  STACKED_AREA,
  DIMENSIONS_NAMES,
  DIMENSION_VALUES,
  BAR_CHART,
} from "../../../constants/multiSelectFilterConstants";
import { useStateWithCallback } from "../../../hooks";
import useBoolean from "../../../hooks/useBoolean";
import { isEmpty } from "../../../utils/is";
import Icon from "../../common/Icon";
import TimePickerIcon from "../../common/TimePickerIcon";
import MultiSelect from "../../common/mui-wrapper-components/Select/MultiSelect";
import { ToggleButtonGroupWithIcon, ToggleGroup } from "../../common/mui-wrapper-components/StyledToggleGroup";
import { IconContainer } from "../../common/styled-components/collaborate/FilterStack.styled";
import LogarithmicScaleSwitch from "./LogarithmicScaleSwitch";
import { EVENTS, PAGE_TYPES } from "../../../constants/commonConstants";

const CHART_TYPES = [
  {name : "metric_compare_chart", value : METRIC_COMPARE, key: "metric_compare", description: "Timeseries Graph"},
  {name : "stacked_area_chart", value : STACKED_AREA, key: "stacked_area", description: "Dimensional Break-up: Stack Chart"},
  {name : "hundred_percent_stacked_area_chart", value : HUNDRED_PERCENT_STACKED_AREA, key: "hundred_percent_stacked_area", description: "Dimensional Break-up: 100% Stack Chart"},
  {name : "bar_chart", value : BAR_CHART, key: "bar_chart", description: "Bar chart"}
];

const FilterChip = ({ label, onDelete, disabled = false }) => {
  return (
    <Chip disabled={disabled} label={label} onDelete={onDelete} size="small" />
  );
};

const useRelatedMetrics = (kpiId, tenantId, pageType, showMetricsDropdown = false) => {
  const dispatch = useDispatch();
  const [kpiMetricOptions, setKpiMetricOptions] = useState([]);

  useEffect(() => {
    let isMounted = true;
    dispatch(getRelatedMetrics(kpiId, { tenant_id: tenantId, is_pixel: pageType === EVENTS && showMetricsDropdown ? true : null })).then(
      (response) => {
        if (isMounted && !isEmpty(response?.kpis)) {
          // We're taking the first index of array and storing it in firstArray to keep the default selected metric on top of list and other data in rest.
          let [firstArray, rest] = [response?.kpis.slice(0, 1), response?.kpis.slice(1)];
          rest.sort((a, b)=> b.kpi_display_name > a.kpi_display_name ? -1 : 1);
          let kpisArray = [...firstArray, ...rest];
          setKpiMetricOptions(kpisArray);

          // dispatch the condition where we mark that the metrics have been fetched
          dispatch({ type: IS_FETCHED, payload: true });
        }
      }
    );

    return () => {
      isMounted = false;
    };
  }, [dispatch, kpiId, tenantId]);

  return kpiMetricOptions;
};

const FilterStack = ({
  tenantId,
  kpiId,
  onTimeFilterClick,
  pipelineSchedule,
  chartType,
  metric,
  globalFilter,
  pageType,
  showMetricsDropdown = false,
}) => {
  const dispatch = useDispatch();
  const PIPELINE_SCHEDULE_OPTIONS = [
    { value: "h", label: "H", description: "Hourly", disabled: PAGE_TYPES.includes(pageType) },
    { value: "d", label: "D", description: "Daily" },
    { value: "w", label: "W", description: "Weekly", disabled: pageType === EVENTS },
    { value: "m", label: "M", description: "Monthly", disabled: true },
  ];
  const [selectedDimension, setSelectedDimension] = useState(null);
  const [selectedDimensionValue, setSelectedDimensionValue] = useState([]);
  const [dimensionValueOptions, setDimensionValueOptions] = useState([]);
  const [dimensionOptions, setDimensionOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMetrics, setSelectedMetrics] = useStateWithCallback([]);
  const [selectedSeverities, setSelectedSeverities] = useState([]);
  const [selectedPatterns, setSelectedPatterns] = useState([]);
  const kpiMetricOptions = useRelatedMetrics(kpiId, tenantId, pageType, showMetricsDropdown);
  const [selectedPipelineSchedule, setSelectedPipelineSchedule] = useState(pipelineSchedule);
  const [selectedChartType, setSelectedChartType] = useState(chartType);
  const [areDropDownsDisabled, { setTrue:  disableDropDowns, setFalse: enableDropDowns }] =
    useBoolean(false);
  const [areChartTypesDisabled, { setTrue: disableChartTypes, setFalse: enableChartTypes }] =
      useBoolean(false);

  useEffect(() => {
    if (isEmpty(kpiMetricOptions)) return;

    const arrayWithTopMetric = [kpiMetricOptions[0]];

    dispatch({ type: KPIS, payload: arrayWithTopMetric });
    setSelectedMetrics(arrayWithTopMetric);

  }, [dispatch, kpiMetricOptions, setSelectedMetrics, metric]);

  useEffect(() => {
    let dimensions = metric?.dimensions;
    if ((selectedChartType === METRIC_COMPARE || selectedChartType === BAR_CHART) && PAGE_TYPES.includes(pageType)) {
      dimensions = metric?.dimensions.filter((item) => {
        // We need to keep
        // dimensions for which split_enabled == true
        // and dimension_name which is not equal to global filter on top navbar as we are anyways doing an and query on top of them
        return item.split_enabled && item.dimension_name !== globalFilter?.dimension_name;
      });
      dimensions.sort((a, b) => (b.dimension_display_name > a.dimension_display_name ? -1 : 1));
      setDimensionOptions(dimensions);
    }
  }, [metric?.dimensions, selectedChartType, globalFilter, pageType]);

  useEffect(()=>{
    return ()=>{
      dispatch({ type: DIMENSIONS_NAMES, payload: [] });
      dispatch({type :DIMENSION_VALUES, payload: []});
      dispatch({ type: CHART_TYPE, payload: METRIC_COMPARE });
    };
  }, [dispatch]);

  const handleMetricsDelete = (kpiName) => () => {
    setSelectedMetrics(
      (prevValue) => prevValue.filter((v) => v.kpi_name !== kpiName),
      (newValue) => {
        // update the store with the new value as soon as state has been set
        dispatch({ type: KPIS, payload: newValue });
        // Disable the chart types as number of selected metrics is greater than 1
        newValue && newValue.length > 1 ? disableChartTypes() : enableChartTypes();
      }
    );
  };

  // Update the Items selected In the dimension values drop down
  const handleSelectedDimensionValueDelete = (dimensionValue) => () => {
    let updatedSelectedDimValues = selectedDimensionValue.filter((v) => v !== dimensionValue);

    // Set dim name to empty if the dimension values array becomes empty
    // This is done to trigger the Compare mettics charts with no dimension array and agins fetch the data for top level metric
    if(updatedSelectedDimValues?.length === 0){
      dispatch({ type: DIMENSIONS_NAMES, payload: [] });
      setSelectedDimension(null);
      enableDropDowns();
    }
    // dispatch the dimension values to Redux store
    dispatch({ type: DIMENSION_VALUES, payload: updatedSelectedDimValues?.length === 0 ? []: updatedSelectedDimValues });
    setSelectedDimensionValue(updatedSelectedDimValues);
  };

  const handleSeverityDelete = (severityType) => () => {
    setSelectedSeverities((prevValue) => {
      let newValue = prevValue.filter((v) => v.severity_type !== severityType);
      dispatch({ type: SEVERITY, payload: newValue });

      return newValue;
    });
  };

  const handlePatternDelete = (patternType) => () => {
    setSelectedPatterns((prevValue) => {
      let newValue = prevValue.filter((v) => v.pattern_type !== patternType);
      newValue && newValue.length ? disableChartTypes() : enableChartTypes();
      dispatch({ type: PATTERN_TYPE, payload: newValue });

      return newValue;
    });
  };

  const handleDimensionChange = (_event, newValue, reason) => {
    let dimension_names_array = [];
    if(!isEmpty(newValue)){
      dimension_names_array.push(newValue);
    }
    setSelectedDimension(newValue);
    if (selectedChartType === METRIC_COMPARE || selectedChartType === BAR_CHART) {
      // Clear the dimension values selection if we are on Metric compare chart
      setSelectedDimensionValue([]);
      if (isEmpty(newValue)) {
        enableDropDowns();
        // empty the selected dimension values array
        dispatch({ type: DIMENSIONS_NAMES, payload: dimension_names_array });
        dispatch({type: DIMENSION_VALUES, payload :[]});
      } else {
        disableDropDowns();
        dispatch({ type: DIMENSIONS_NAMES, payload: dimension_names_array });
        fetchDimensionValues(newValue.dimension_id, newValue);
      }
    } else {
      dispatch({ type: DIMENSIONS_NAMES, payload: dimension_names_array });
    }
  };

  const fetchDimensionValues = (dimensionId, selectedDimension = null) => {
    dispatch(
      getDimensionValues(dimensionId, { tenant_id: tenantId })
    )
      .then((response) => {
        let updatedResponse = response?.map((element) => element === null ? 'null' : element );
        setDimensionValueOptions(updatedResponse);
        setLoading(false);
      })
      .catch((err) => {
        // TODO
      });
  };

  const handleDimensionValueChange = (_event, newValue, reason) => {
    // Set dim name to empty if the dimension values array becomes empty
    // This is done to trigger the Compare mettics charts with no dimension array and agins fetch the data for top level metric
    if(Array.isArray(newValue) && newValue?.length === 0){
      dispatch({ type: DIMENSIONS_NAMES, payload: [] });
      setSelectedDimension(null);
      enableDropDowns();
    }
    dispatch({ type: DIMENSION_VALUES, payload: newValue });
    setSelectedDimensionValue(newValue);
  };

  function handleMetricChange(_event, newValue, reason) {
    if (reason === "clear") {
      dispatch({ type: KPIS, payload: [kpiMetricOptions[0]] });
      setSelectedMetrics([kpiMetricOptions[0]]);
    } else {
      dispatch({ type: KPIS, payload: newValue });
      setSelectedMetrics(newValue);
      // Disable the chart types as number of selected metrics is greater than 1
      newValue && newValue.length > 1 ? disableChartTypes() : enableChartTypes();
    }
  }

  function handlePatternChange(_event, newValue) {
    dispatch({ type: PATTERN_TYPE, payload: newValue });
    newValue && newValue.length ? disableChartTypes() : enableChartTypes();
    setSelectedPatterns(newValue);
  }

  function handleSeverityChange(_event, newValue) {
    dispatch({ type: SEVERITY, payload: newValue });
    setSelectedSeverities(newValue);
  }

  function handleChartTypeChange(_event, newValue){
    if (newValue === METRIC_COMPARE || newValue === BAR_CHART) {
      // Reset Dimension name and value drop downs selections when coming back to the timeseries chart
      setSelectedDimension(null);
      dispatch({ type: DIMENSIONS_NAMES, payload: [] });
      dispatch({ type: DIMENSION_VALUES, payload: [] });
    } else {
      // declare dimension_names_array array
      let dimension_names_array = [];
      // filter dimension_names_array array
      dimension_names_array = metric?.dimensions.filter((item) => {
        // We need to keep
        // dimension_name which is not equal to global filter on top navbar as we are anyways doing an and query on top of them
        return item.dimension_name !== globalFilter?.dimension_name;
      });
      // Set Dimension Name to the First option in the available dimension_names_array option
      let selected_dimensions_array = [];

      if (dimension_names_array?.length > 0) {
        // sort based on the display name
        dimension_names_array.sort((a, b) => (b.dimension_display_name > a.dimension_display_name ? -1 : 1));
        // set the drop down list
        setDimensionOptions(dimension_names_array);
        // prepare the selected dimension_names_array list on render of the chart
        selected_dimensions_array.push(dimension_names_array[0]);
      }
      // Set the Dimension names Drop down to 1st Element in the list
      dispatch({ type: DIMENSIONS_NAMES, payload: selected_dimensions_array });
      // Empty array to be set when We are not on Dimensions Breakup Chart
      setSelectedDimensionValue([]);
      dispatch({ type: DIMENSION_VALUES, payload: [] });

      // if the length of the dimension_names_array is greater than 0 then set the first dimension as default selected
      dimension_names_array?.length > 0 ? setSelectedDimension(dimension_names_array[0]) : setSelectedDimension(null);
    }
    dispatch({ type: CHART_TYPE, payload: newValue });
    // Find a match
    let isMatch = CHART_TYPES.slice(1).some(chartType =>  newValue === chartType.value);
    // If matched disable the dropdowns or if chart is metric compare and dimension is selected then disable the dropdown.
    if (isMatch) {
      disableDropDowns();
    } else if(selectedDimension?.split_enabled) {
      disableDropDowns();
    } else {
      enableDropDowns();
    }
    setSelectedChartType(newValue);
  }

  function handlePipelineScheduleChange(_event, newValue){
    dispatch({ type: PIPELINE_SCHEDULE, payload: newValue });
    setSelectedPipelineSchedule(newValue);
  }

  return (
    <Stack data-snippyly-comment-disabled width="100%" spacing={4}>
      <Stack direction="row" width="100%" gap={{ sm: 2, sl: 4 }}>
        <IconContainer>
          <TimePickerIcon position="relative" right="0" onClick={onTimeFilterClick} />
        </IconContainer>

        {showMetricsDropdown ? (
          <MultiSelect
            disabled={areDropDownsDisabled}
            placeholder="Add Metric"
            onChange={handleMetricChange}
            clearIcon={selectedMetrics.length === 1 ? null : undefined}
            value={selectedMetrics}
            options={kpiMetricOptions}
            getOptionLabel={(option) => option.kpi_display_name}
            getOptionDisabled={(option) => kpiMetricOptions[0].kpi_id === option.kpi_id}
            isOptionEqualToValue={(option, value) => option.kpi_id === value.kpi_id}
          />
        ) : null}
        {/* Need to hide the dimension drop down in case of filtered views*/}
        {(globalFilter?.dimension_name === null ||
          [STACKED_AREA, HUNDRED_PERCENT_STACKED_AREA].includes(selectedChartType)) &&
        dimensionOptions &&
        dimensionOptions.length ? (
          <MultiSelect
            multiple={false}
            disableClearable
            placeholder="Add Dimension"
            onChange={handleDimensionChange}
            disabled={
              (selectedChartType === METRIC_COMPARE || selectedChartType === BAR_CHART) &&
              (selectedMetrics.length > 1 || selectedPatterns?.length > 0)
            }
            clearIcon={selectedDimension?.length === 1 ? null : undefined}
            value={selectedDimension}
            options={dimensionOptions}
            getOptionLabel={(option) => option.dimension_display_name}
            isOptionEqualToValue={(option, value) => option.dimension_id === value.dimension_id}
          />
        ) : null}

        {!isEmpty(selectedDimension) &&
        (selectedChartType === METRIC_COMPARE || selectedChartType === BAR_CHART) &&
        PAGE_TYPES.includes(pageType) ? (
          <MultiSelect
            multiple={true}
            placeholder="Add Dimension value"
            onChange={handleDimensionValueChange}
            disableClearable
            value={selectedDimensionValue}
            options={dimensionValueOptions}
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(option, value) => option === value}
          />
        ) : null}

        {/* Commenting out add pattern in metrics l3 as it is not in use right now.
        <MultiSelect
          disabled={areDropDownsDisabled}
          placeholder={PATTERN}
          value={selectedPatterns}
          onChange={handlePatternChange}
          options={DROPDOWN_TYPE.PATTERN}
          getOptionLabel={(option) => option.pattern_value}
          isOptionEqualToValue={(option, value) => option.pattern_value === value.pattern_value}
        /> */}

        {/*  DEV-4402 : - Commenting the severity drop down. Not removing it as it might be needed later
        <MultiSelect
          placeholder={SEVERITY}
          value={selectedSeverities}
          onChange={handleSeverityChange}
          options={DROPDOWN_TYPE.SEVERITY}
          getOptionLabel={(option) => option.severity_value}
          isOptionEqualToValue={(option, value) => option.severity_value === value.severity_value}
        /> */}
        {PAGE_TYPES.includes(pageType) ? <LogarithmicScaleSwitch /> : null}

        <Stack direction="row" ml="auto" gap={10}>
            <ToggleGroup
              value={selectedPipelineSchedule}
              sx={{ width: "100%", height: 38 }}
              options={PIPELINE_SCHEDULE_OPTIONS}
              onChange={(event, value) => handlePipelineScheduleChange(event, value)}
            />
          <ToggleButtonGroupWithIcon
            value={selectedChartType}
            onChange={(event, value) => handleChartTypeChange(event, value)}
            sx={{
              width: "100%",
              height: 38,
            }}
          >
            {CHART_TYPES.map((chartType) => (
              <Tooltip key={chartType.key} title={chartType.description} placement="bottom-end">
                <ToggleButton
                  disabled={areChartTypesDisabled}
                  value={chartType.value}
                  key={chartType.key}
                >
                  <Icon
                    name={chartType.name}
                    color={selectedChartType === chartType.value ? "#245ae6" : null}
                  ></Icon>
                </ToggleButton>
              </Tooltip>
            ))}
          </ToggleButtonGroupWithIcon>
        </Stack>
      </Stack>

      <Box>
        <Stack
          alignItems="center"
          direction="row"
          flexWrap="wrap"
          gap={4}
          width="100%"
          fontSize="16px"
        >
          {!isEmpty(selectedMetrics) && showMetricsDropdown ? (
            <Stack direction="row" spacing={2}>
              <span>Metrics:</span>
              <Stack direction="row" spacing={2} flexWrap="wrap" rowGap={1}>
                {selectedMetrics.map((v, index) => (
                  <FilterChip
                    disabled={index === 0}
                    key={v.kpi_name}
                    label={v.kpi_display_name}
                    onDelete={handleMetricsDelete(v.kpi_name)}
                  />
                ))}
              </Stack>
            </Stack>
          ) : null}

          {!isEmpty(selectedPatterns) ? (
            <Stack direction="row" spacing={2}>
              <span>Patterns:</span>

              <Stack direction="row" spacing={2}>
                {selectedPatterns.map((v, index) => (
                  <FilterChip
                    key={v.pattern_type}
                    label={v.pattern_value}
                    onDelete={handlePatternDelete(v.pattern_type)}
                  />
                ))}
              </Stack>
            </Stack>
          ) : null}

          {!isEmpty(selectedSeverities) ? (
            <Stack direction="row" spacing={2}>
              <span>Severity:</span>
              <Stack direction="row" spacing={2}>
                {selectedSeverities.map((v) => (
                  <FilterChip
                    key={v.severity_type}
                    label={v.severity_value}
                    onDelete={handleSeverityDelete(v.severity_type)}
                  />
                ))}
              </Stack>
            </Stack>
          ) : null}
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          flexWrap="wrap"
          gap={4}
          width="100%"
          fontSize="16px"
        >
          {!isEmpty(selectedDimensionValue) ? (
            <Stack direction="row" mt={3} spacing={2}>
              <span>Dimension Values:</span>
              <Stack direction="row" spacing={2} flexWrap="wrap" rowGap={1}>
                {selectedDimensionValue.map((v, index) => (
                  <FilterChip
                    key={v}
                    label={v}
                    onDelete={handleSelectedDimensionValueDelete(v)}
                  />
                ))}
              </Stack>
            </Stack>
          ) : null}
        </Stack>
      </Box>
    </Stack>
  );
};

export default FilterStack;
