import { useSearchParams } from "react-router-dom";
import { useAuthenticatedUser } from "./useAuthenticatedUser";
import { useLocalStorageState } from "./useLocalStorageState";

export function useSelectedTenant() {
  const user = useAuthenticatedUser();
  const [selectedTenant] = useLocalStorageState("selectedTenant");
  const [searchParams, setSearchParams] = useSearchParams();
  const tenantId = searchParams.get("tenant_id");
  return tenantId || selectedTenant || user?.tenant_id;
}
